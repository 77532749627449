import './App.css';
import MainPage from './components/MainPage/MainPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'

// import Login from './components/Authentication/login_component'
// import SignUp from './components/Authentication/signup_component'


function App() {
  // const isLoggedIn = window.localStorage.getItem("loggedIn"); 

  return (
    <div className="App">
     <MainPage/>
    </div>
  );
}

export default App;
