import React,{ useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
// import './Grid.css'

import ltb from '../../Assets/images/logos/ltb.png';
import ojibwelogo from '../../Assets/images/logos/ojibwe.png';
import tatalogo from '../../Assets/images/logos/tata.png';
import patwinlogo from '../../Assets/images/logos/patwin.png';
import shakopeelogo from '../../Assets/images/logos/shakopee.png';
import dehchologo from '../../Assets/images/logos/dfn.png';
import whitecaplogo from '../../Assets/images/logos/whitecap.png';
import bellalogo from '../../Assets/images/logos/bellabella.png'

function Grid() {
    const images = [     
        {src: patwinlogo, link: "https://apps.ogokilearning.com/yochadehewintun/"},
        {src: shakopeelogo, link: "https://apps.ogokilearning.com/shakopee/"},
        {src: ojibwelogo, link: "https://apps.ogokilearning.com/akrc/"},
        {src: whitecaplogo, link: "https://apps.ogokilearning.com/whitecap/"},
        {src: ltb, link: "https://apps.ogokilearning.com/littletraversebay/"},
        {src: tatalogo, link: "https://apps.ogokilearning.com/tataskweyak/"},
        {src: dehchologo, link: "https://apps.ogokilearning.com/dehcho/"},
        {src: bellalogo, link: "https://apps.ogokilearning.com/heiltsuk/"},
      ];

      const [styles, setStyles] = useState(
        new Array(images.length).fill({ transition: 'transform 0.2s', width: '50%', height: 'auto', marginBottom:'20%',})
      );
    
      const handleMouseEnter = (index) => {
        const newStyles = [...styles];
        newStyles[index] = { transform: 'scale(1.1)', transition: 'transform 0.2s',width: '50%', height: 'auto',marginBottom:'20%', };
        setStyles(newStyles);
      };
    
      const handleMouseLeave = (index) => {
        const newStyles = [...styles];
        newStyles[index] = { transition: 'transform 0.2s',width: '50%', height: 'auto',marginBottom:'20%', };
        setStyles(newStyles);
      };
   

    return (
        <Container className='gridWrapper'>
        <Row>
            {images.map((img, i) => (
                <Col lg={4} md={4} sm={4} xs={6} key={i}>
                    <a href={img.link}>
                        <img 
                            src={img.src} 
                            alt="" 
                            style={styles[i]} 
                            onMouseEnter={() => handleMouseEnter(i)} 
                            onMouseLeave={() => handleMouseLeave(i)} 
                        />
                    </a>
                </Col>
            ))}
        </Row>
    </Container>
    )
}

export default Grid