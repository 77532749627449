import React, { useState, useEffect } from 'react';
import Grid from '../Grid/Grid.js';
import './MainPage.css'

function MainPage() {
    const [windowDimensions, setWindowDimensions] = useState({ 
        width: window.innerWidth, 
        height: window.innerHeight 
    });
    
    useEffect(() => {
        
        function handleResize() {
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        });
        }
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
      }, []); 

  return (
    <div className="contentWrapper">
        <div className='title'>
            <h1 className='titleContent' style={{ fontSize: windowDimensions.width > 700 ? windowDimensions.width / 30 : windowDimensions.width / 20 }}>
                Ogoki Learning Showcase
            </h1>
        </div>

        <div className='gridContainer'>
        <Grid />
        </div>
    </div>
  )
}

export default MainPage